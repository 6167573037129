#background-video{
    z-index: -1;
    min-height: 100vh;
    min-width: 100vh;
    /*max-height: 100vh;*/
    /*max-width: 100vh;*/
    overflow: hidden;
    float: left;
    top: 0;
    ;
    /*padding: none;*/
    position: fixed; /* optional depending on what you want to do in your app */


}