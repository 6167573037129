.heading {
   font-family: "Poppins", sans-serif;
   color: yellow;
   display: block;
   margin-block-start: 0.67em;
   margin-block-end: 0.67em;
   margin-inline-start: 0px;
   margin-inline-end: 0px;
   font-weight: normal;
   font-size: 60px;
   margin: 250px 0 40px;
}

.countdown {
   z-index: 99;
   display: flex;
   align-items: center;
   justify-content: center;
   color: yellow;
   transform: scale(2);
}
.time {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   margin: 15px;
}

.time h2 {
   font-weight: bold;
   font-size: 36px;
   line-height: 1;
   margin: 0 0 5px;
}
small {
   font-size: smaller;
}

