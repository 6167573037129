a{
    cursor: pointer;
}

.bio{
    margin-left: 30px;
    margin-top: 120px;
    width: max-content;
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
}

.bio-name{
    color: #fec342;
    font-size: 26px;
    font-weight: bolder;
    margin-bottom: 10px;
}

.education{
    margin-left: 30px;
    margin-top: 120px;
    width: 400px;
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
}

.education-name{
    font-size: 26px;
    font-weight: bolder;
    margin-bottom: 10px;
}

.education-column{
    display: flex;
    justify-content: space-between;
}

.education-university{
    font-size: 14px;
    font-weight: lighter;
}

.work-experience{
    margin-left: 30px;
    margin-top: 120px;
    width: 500px;
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
}





.work-experience-name{
    font-size: 26px;
    font-weight: bolder;
    margin-bottom: 10px;
}

.work-experience__column{
    display: flex;
    justify-content: space-between;
}

.work-experience__company-column{
    display: flex;
}

.work-experience__company-name{
    font-size: 14px;
    font-weight: lighter;
    /*margin-bottom: 12px;*/
}

.socials{
    margin-left: 30px;
    margin-top: 120px;
    width: 175px;
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
}

.socials-name{
    font-size: 26px;
    font-weight: bolder;
    margin-bottom: 10px;
}

.socials-img{
    width: 35px;
    height: 35px;
}

.socials-column{
    display: flex;
    justify-content: space-between;
}

.contact-me{
    margin-left: 30px;
    margin-top: 120px;
    width: 300px;
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
}

.contact-me__name{
    font-size: 26px;
    font-weight: bolder;
    margin-bottom: 10px;
}

.contact-me__contact-form{
    color: #fec342;
    font-style: oblique;
    font-size: 14px;
}

.contact-me__mail a{
    text-decoration: none;
    color: white;
}





